import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import sanitizeHtml from 'sanitize-html-react'

// COMPONENTS
// import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import StyledLink from '../utils/Link'
import { MAX_WIDTH } from '../utils/MediaQueries'

// STYLES
import { ColorScheme, ANIMATION_DELAY } from '../utils/Variables'
import { BlogPostHeader, BlogPostHeaderCaption } from '../styles/Headers'
import {
  BlogPostContainer,
  BlogPostPaginationContainer,
} from '../styles/Containers'

const PaginationButton = styled.div`
  transition: all ${ANIMATION_DELAY};
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    margin: 0;
    padding: 30px;
  }

  span {
    font-size: 10px;
    letter-spacing: 0.25em;
    line-height: 3em;
    text-transform: uppercase;
  }

  &:hover {
    background-color: ${ColorScheme.offWhite};

    span {
      color: rgba(29, 29, 29, 0.3);
    }

    a {
      color: ${ColorScheme.main};
      text-decoration: underline !important;
    }
  }
`

const PaginationPrevious = styled(PaginationButton)`
  span {
    position: absolute;
    left: 10px;
    top: 0;
  }
`
const PaginationNext = styled(PaginationButton)`
  span {
    position: absolute;
    right: 10px;
    top: 0;
  }
`

const BlogPost = styled.div`
  padding: 30px 30px 0 30px;

  a:focus,
  a:visited,
  a:link,
  a:active {
    box-shadow: none;
    text-decoration: none;
    /* color: #19608b; */
    color: ${ColorScheme.main};
    font-weight: 500;
    padding-bottom: 1px;
    transition: ${ANIMATION_DELAY};
  }

  a:hover {
    color: #020f31;
    border-bottom: 1px dashed #020f31;
  }

  img {
    display: block;
    object-fit: cover;
    max-width: 450px;
    max-height: 450px;
    margin: 25px auto 0;
    transition: all 0.5s ease-in-out;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);

    &:hover {
      transform: scale(1.02);
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
    }
  }

  p {
    margin: 10px 0;
  }

  p:first-of-type {
    margin: 0 0 10px 0;
  }

  p:last-of-type {
    margin: 10px 0 0 0;
  }

  @media (max-width: ${MAX_WIDTH}px) {
    padding: 30px 30px 0 30px;
    font-size: 15px;
    width: 100%;

    img {
      width: 100%;
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
    }
  }
`

class BlogPostTemplate extends React.Component {
  componentDidMount() {
    const blogPostImages = document
      .querySelector('.blogPost')
      .querySelectorAll('img')
    const images = Array.from(blogPostImages)
    images.forEach(image => {
      const parent = image.parentElement
      const imageCaption = parent.nextSibling.nextElementSibling
      // let width;
      // if (image.width === 0){
      //   width = '100%';
      // } else {
      //   width = `${image.width}px`;
      //   imageCaption.style.margin = '0 auto 25px';
      // }

      imageCaption.style.textAlign = 'center'
      imageCaption.style.color = ColorScheme.darkGrey
      // imageCaption.style.width = width;
      imageCaption.style.fontSize = '14px'
    })
  }

  render() {
    const { location, data, pageContext } = this.props
    const post = data.markdownRemark
    const siteTitle = data.site.siteMetadata.title
    const { previous, next } = pageContext
    const { title, photo, date, photo_caption: photoCaption } = post.frontmatter

    return (
      <Layout location={location} title={siteTitle}>
        <SEO title={title} description={post.excerpt} />
        <BlogPostHeader bgPhoto={photo}>
          <div className="meta">
            <h1>{title}</h1>
            <span>{date}</span>
          </div>
        </BlogPostHeader>
        <BlogPostHeaderCaption>{photoCaption}</BlogPostHeaderCaption>
        <BlogPostContainer>
          <BlogPost
            className="blogPost"
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(post.html, {
                allowedTags: false,
                allowedAttributes: false,
                transformTags: {
                  a: sanitizeHtml.simpleTransform('a', { target: '_blank' }),
                },
              }),
            }}
          />
          <BlogPostPaginationContainer>
            {previous && (
              <PaginationPrevious show={!previous}>
                <StyledLink
                  hovercolor={ColorScheme.black}
                  color="#fff"
                  to={previous.fields.slug}
                  rel="prev"
                >
                  <p>{previous.frontmatter.title}</p>
                  <span>← Previous Post</span>
                </StyledLink>
              </PaginationPrevious>
            )}
            {next && (
              <PaginationNext show={!next}>
                <StyledLink
                  hovercolor={ColorScheme.black}
                  color="#fff"
                  to={next.fields.slug}
                  rel="next"
                >
                  <span>Next Post →</span>
                  <p>{next.frontmatter.title}</p>
                </StyledLink>
              </PaginationNext>
            )}
          </BlogPostPaginationContainer>
        </BlogPostContainer>
        {/* <Bio /> */}
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        photo
        photo_caption
      }
    }
  }
`
