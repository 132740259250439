import styled from 'styled-components'
import { ColorScheme } from '../utils/Variables'
import { MED_WIDTH } from '../utils/MediaQueries'

export const Container = styled.div`
  margin: 20px auto;
  font-size: 0.9rem;
  background-color: ${ColorScheme.offWhite};
  padding: 30px;
  width: 80%;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
`

export const AboutPageContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  justify-content: center;
  grid-gap: 20px;

  p {
    margin-bottom: 0 0 15px;
  }

  a {
    color: black;
    text-decoration: underline;
  }

  @media (max-width: ${MED_WIDTH}px) {
    width: 90%;
    padding: 20px;
    grid-template-columns: 1fr;
    grid-gap: 10px;

    img {
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
    }
  }
`

export const BioContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 20px;
  width: 50%;
  margin: 0 auto;
  background-color: ${ColorScheme.grey};
  padding: 20px;
  transition: 200ms box-shadow ease;
  letter-spacing: -0.5px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);

  > div {
    font-size: 0.9rem;
    line-height: 1.2;
  }

  h3 {
    margin: 0;
    font-size: 1.2rem;
  }

  a {
    font-weight: bold;
    text-decoration: underline !important;
  }

  &:hover {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: ${MED_WIDTH}px) {
    grid-template-columns: 1fr;
    width: 90%;
    margin: 0 auto;
    font-size: 0.9rem;
    grid-gap: 10px;
    padding: 10px;

    .gatsby-image-wrapper {
      margin: 0 auto;
    }
  }
`

export const BlogPostContainer = styled(Container)`
  padding: 0;
  box-shadow: none;
  width: 90%;

  @media (max-width: ${MED_WIDTH}px) {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    width: 100%;
  }
`

export const BlogPostPaginationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${ColorScheme.black};
  color: ${ColorScheme.offWhite};
  margin: 30px 0 0;

  @media (max-width: ${MED_WIDTH}px) {
    flex-direction: column;
  }
`
