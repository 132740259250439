import styled from 'styled-components'
import { MAX_WIDTH } from '../utils/MediaQueries'
import { ColorScheme, BLACK_TRANSPARENT_BG } from '../utils/Variables'

export const H1 = styled.h1`
  padding: 0;
  font-weight: 400;
  letter-spacing: -1px;
  font-size: 2rem;
  color: ${ColorScheme.black};
  margin: 0 0 5px;
`

export const BlogPostHeader = styled.div`
  position: relative;
  height: 700px;
  background-position: center;
  background-image: ${props => `url(${props.bgPhoto})`};
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;

  .meta {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    text-align: center;
    background-color: ${BLACK_TRANSPARENT_BG};
    color: ${ColorScheme.white};
    width: 60%;
    margin: auto;
    padding: 20px;

    h1 {
      margin: 0;
      padding: 0;
      text-transform: capitalize;
      letter-spacing: -1px;
    }
  }

  @media (max-width: ${MAX_WIDTH}px) {
    height: 350px;

    .meta {
      width: 70%;
      padding: 10px;

      h1 {
        font-size: 2rem;
      }
    }
  }
`

export const BlogPostHeaderCaption = styled.div`
  margin: 10px auto;
  text-align: center;
  font-size: 18px;
  user-select: none;
  color: ${ColorScheme.darkGrey};

  @media (max-width: ${MAX_WIDTH}px) {
    width: 90%;
    font-size: 15px;
  }
`
